









import { Component, Prop, Vue } from 'vue-property-decorator'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'

@Component({
  components: { ScaleLoader },
})
export default class LoadingWrapper extends Vue {
  @Prop({ type: Boolean }) loading: boolean | null
  @Prop({ type: String }) loader

  get isLoading() {
    if (this.loading) return true
    if (this.loader !== null) {
      return this.$wait.is(this.loader)
    }
    return false
  }
}
