
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Debounce } from 'lodash-decorators'

export enum State {
  Loading = 'Loading',
  Resolved = 'Resolved',
  Rejected = 'Rejected',
}

@Component
export default class ApiProvider extends Vue {
  @Prop({ required: true, type: String }) url

  state: State = State.Loading
  data: unknown = null

  @Watch('url', { immediate: true })
  @Debounce(200)
  fetchData() {
    this.$axios
      .get(this.url)
      .then((response) => {
        this.data = response.data
        this.state = State.Resolved
      })
      .catch((_error) => {
        this.state = State.Rejected
      })
  }

  render(_h) {
    return this.$scopedSlots.default(this.data)
  }
}
