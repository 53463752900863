






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ScrollWrapper extends Vue {
  @Prop({ default: '100%' }) height: string

  get computedHeight() {
    if (!isNaN(parseInt(this.height)) || typeof this.height === 'number') {
      return `${this.height}px`
    }
    return this.height
  }
}
