
































import { Component, Model, Prop, Vue } from 'vue-property-decorator'
import { TableHeader } from '@/types/Vuetify'

@Component
export default class OrderModal extends Vue {
  @Prop() order: any
  @Prop() supplierId: number
  @Model('change') dialog!: boolean

  orderProductsHeaders: TableHeader[] = [
    {
      text: 'Status',
      value: 'deliveryStatus',
    },
    {
      text: 'Product name',
      value: 'productName',
    },
    {
      text: 'Quantity',
      value: 'quantity',
    },
    {
      text: 'In-stock',
      value: 'stockItemsCount',
    },
    {
      text: 'Position(s)',
      value: '',
      sortable: false,
    },
  ]

  get passThroughDialog(): boolean {
    return this.dialog
  }

  set passThroughDialog(value) {
    this.$emit('change', value)
  }

  get orderId(): number {
    return this.order && this.order.id
  }

  get deliveryName(): string {
    return this.order && this.order.deliveryName
  }

  get orderProducts(): any[] {
    return (
      this.order &&
      this.order.orderProducts.map((product) => ({
        ...product,
        deliveryStatus: this.evaluateStatus(product),
        stockPosition: Array.from(new Set(product.stockItems.map(({ stockPosition }) => stockPosition.name))).join(
          ', ',
        ),
      }))
    )
  }

  evaluateStatus(product: any) {
    if (product.quantity === product.stockItemsCount) {
      return 'Ready for shipment'
    } else if (product.stockItemsCount > 0) {
      return 'Partially ready for shipment'
    } else if (product.supplierId !== this.supplierId) {
      return 'Ordered from external supplier'
    }
    return 'Unknown status'
  }

  customSort(items: any[], index: string, isDescending: boolean): any[] {
    if (index === null) return items

    return items.sort((a, b) => {
      let sortA = a[index]
      let sortB = b[index]

      if (isDescending) {
        [sortA, sortB] = [sortB, sortA]
      }

      if (index === 'deliveryStatus') {
        const statusToNumber = (status) => {
          switch (status) {
            case 'Ready for shipment':
              return 0
            case 'Partially ready for shipment':
              return 1
            case 'Ordered from external supplier':
              return 2
          }
          return 3
        }
        sortA = statusToNumber(sortA)
        sortB = statusToNumber(sortB)
      }

      // Check if both are numbers
      if (!isNaN(sortA) && !isNaN(sortB)) {
        return sortA - sortB
      }

      // Check if both cannot be evaluated
      if (sortA === null && sortB === null) {
        return 0
      }

      [sortA, sortB] = [sortA, sortB].map((s) => (s || '').toString().toLocaleLowerCase())

      if (sortA > sortB) return 1
      if (sortA < sortB) return -1

      return 0
    })
  }
}
