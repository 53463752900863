






























































































import { Component, Vue, Watch } from 'vue-property-decorator'
import GoogleMaps from '@/components/maps/GoogleMaps.vue'
import { Debounce } from 'lodash-decorators'
import MapService from '@/services/MapsService'
import ScrollWrapper from '@/components/helpers/ScrollWrapper.vue'
import LoadingWrapper from '@/components/helpers/LoadingWrapper.vue'
import { WaitStart } from '@/utils/Decorators'
import ApiProvider from '@/components/data-providers/ApiProvider.vue'
import { vxm } from '@/store'
import OrderModal from '@/components/logistics/OrderModal.vue'
import { oc } from 'ts-optchain'
import { VForm, VSelect } from '@/types/Vuetify'
import UnassignedLogisticsOrders from '@/components/logistics/UnassignedLogisticsOrders.vue'
import SelectedLogisticsOrders from '@/components/logistics/SelectedLogisticsOrders.vue'
import { axios } from '@/plugins/vueaxios'
// eslint-disable-next-line no-undef
import LatLngLiteral = google.maps.LatLngLiteral

@Component({
  components: {
    SelectedLogisticsOrders,
    UnassignedLogisticsOrders,
    OrderModal,
    ApiProvider,
    LoadingWrapper,
    ScrollWrapper,
    GoogleMaps,
  },
})
export default class Dashboard extends Vue {
  $refs: Vue['$refs'] & {
    form: VForm
    deliveryVehicle: VSelect
  }

  alert = vxm.alert

  deliveryVehicles: { type: string; pieces: number }[] = [
    { type: 'Medium vehicle', pieces: 60 },
    { type: 'Small vehicle', pieces: 30 },
    { type: 'Mega vehicle', pieces: 99999 },
  ]

  supplierId: number = null
  unassignedOrders: any[] = []
  locations: any[] = []
  drivers: any[] = []

  selectedOrders: any[] = []

  driver: number | null = null
  routeName = ''
  customerNameFilter = ''
  deliveryVehicle: { type: string; pieces: number } = null
  orderedRoute: any[] = []
  route: google.maps.DirectionsResult | null = null

  get deliveryVehicleCapacity() {
    return oc(this.deliveryVehicle).pieces(0)
  }

  get location(): any {
    if (this.locationId !== null) {
      return this.locations.find(({ id }) => id === this.locationId)
    }
    return null
  }

  get locationId(): number | null {
    return parseInt(vxm.user.userSettings.logisticsSelectedLocation) || null
  }

  set locationId(locationId: number) {
    vxm.user.putUserSetting({ key: 'logisticsSelectedLocation', value: locationId.toString() })
  }

  get unselectedOrders() {
    const selected = this.selectedOrders.map(({ id }) => id)
    return this.unassignedOrders.filter(({ id }) => !selected.includes(id))
  }

  get bounds() {
    if (this.route !== null) {
      return this.route.routes[0].bounds.toJSON()
    }
    return null
  }

  get points(): google.maps.MarkerOptions[] {
    let result = []
    if (this.route !== null) {
      const route = this.route.routes[0]
      const hub = route.legs[0].start_location
      result = [
        {
          position: hub.toJSON(),
          title: this.location.name,
        },
      ]
      result.push(
        ...route.legs
          .map((leg, index) => {
            return {
              tmp: leg.end_location,
              position: leg.end_location.toJSON(),
              title: leg.end_address,
              label: `${index + 1}`,
            }
          })
          .filter((opts) => !opts.tmp.equals(hub))
          .map(({ tmp, ...opts }, index) => opts),
      )
    }
    return result
  }

  get deliveryVehicleRules() {
    return [
      (value) => value !== null || this.$t('You have to choose a delivery vehicle'),
      (value) =>
        (value && value.pieces >= this.piecesInRoute) ||
        this.$t('The selected delivery vehicle might not fit all of the items selected'),
    ]
  }

  get piecesInRoute(): number {
    return this.selectedOrders.reduce((acc, order) => acc + order.readyProductsCount, 0)
  }

  get routePath(): LatLngLiteral[] {
    if (this.route !== null) {
      return this.route.routes[0].legs.flatMap((leg) => {
        return leg.steps.flatMap((step) => step.path.flatMap((latLng) => latLng.toJSON()))
      })
    }
    return []
  }

  mounted() {
    this.loadLocations()
    this.loadDrivers()
  }

  async deselectOrders(ids: number[]) {
    this.selectedOrders = this.selectedOrders.filter((order) => !ids.includes(order.id))
  }

  async selectOrders(ids: number[]) {
    if (ids.length === 0) return

    const orders = this.unassignedOrders
      .map((order, index) => ({ order, index }))
      .filter(({ order }) => ids.includes(order.id))
    const ordersWithoutLocation = orders.filter(({ order }) => typeof order.normalizedAddress === 'undefined')
    const mapService = ordersWithoutLocation.length > 0 ? await MapService : null
    await Promise.all(
      ordersWithoutLocation.map(async ({ order, index }) => {
        const updatedOrder = {
          ...order,
          normalizedAddress: await mapService
            .normalizeAddress(`${order.deliveryAddress1}, ${order.deliveryZipcode} ${order.deliveryCity}`)
            .then(({ results }) => results[0])
            .then((result) => ({ formattedAddress: result.formatted_address, placeId: result.place_id })),
        }

        this.$set(this.unassignedOrders, index, updatedOrder)
      }),
    )

    this.selectedOrders = [...this.selectedOrders, ...orders.map(({ index }) => this.unassignedOrders[index])]
  }

  @Watch('selectedOrders')
  onSelectedOrdersChanged() {
    if (this.selectedOrders.length > 0) {
      this.$refs.deliveryVehicle.validate(true)
    }
  }

  @Watch('selectedOrders')
  @WaitStart('calculate.route')
  @Debounce(1000)
  async sortOrdersAccordingToRoute() {
    if (this.selectedOrders.length > 0) {
      const hub = `${this.location.address1}, ${this.location.zipcode} ${this.location.city}`
      this.$wait.start('calculatingRouteOrder')
      await MapService.then((mapService) => {
        return mapService.getDirectionsRoute(
          hub,
          hub,
          this.selectedOrders.map((order) => ({
            location: order.place,
            stopover: true,
          })),
        )
      })
        .then((result) => {
          this.route = result
          this.orderedRoute = result.routes[0].waypoint_order.map((i, index) => ({
            ...this.selectedOrders[i],
            index,
          }))
        })
        .finally(() => {
          this.$wait.end('calculatingRouteOrder')
        })
    } else {
      this.orderedRoute = []
      this.route = null
    }
    this.$wait.end('calculate.route')
  }

  generateRoute() {
    if (this.$refs.form.validate()) {
      this.$wait.start('create.logistics.route')
      this.$axios
        .post('/v3/logistics/routes', {
          name: this.routeName,
          locationId: this.locationId,
          orders: this.selectedOrders.map((order) => ({
            id: order.id,
            deliveryName: order.deliveryName,
            deliveryPhone: order.deliveryPhone,
            deliveryComment: order.deliveryComment,
            normalizedAddress: order.normalizedAddress,
            stockItems: order.orderProducts
              .reduce((items, item) => [...items, ...item.stockItems], [])
              .map((item) => item.id),
          })),
          route: this.route,
        })
        .then(({ data }) => data.data)
        .then((batch) => {
          this.$router.push({
            name: 'Stock/Logistics/Admin',
          })
        })
        .finally(() => {
          this.$wait.end('create.logistics.route')
        })
    }
  }

  private async loadLocations() {
    this.locations = await this.$axios.get('/v3/location').then(({ data }) => data.data)
  }

  @Watch('locationId', { immediate: true })
  @Watch('customerNameFilter')
  @Debounce(1000)
  async loadUnassignedOrders() {
    const id = this.locationId
    if (id == null) return
    this.$wait.start('loadUnassignedOrders')
    try {
      const response = await axios.get(`/v3/logistics/orders/${id}?customer_name=${this.customerNameFilter}`)
      const { orders, supplierId } = response.data.data

      this.supplierId = supplierId
      this.unassignedOrders = await Promise.all(
        orders.map(async (order) => ({
          ...order,
          place: `${order.deliveryAddress1}, ${order.deliveryZipcode} ${order.deliveryCity}`,
          deliveryName: order.deliveryName,
          deliveryPlace: `${order.deliveryZipcode} ${order.deliveryCity}`,
          readyProductsCount: order.orderProducts.reduce((sum, product) => sum + product.stockItemsCount, 0),
          productsCount: order.orderProducts.reduce((sum, product) => sum + product.quantity, 0),
          normalizedAddress: undefined,
        })),
      )
    } finally {
      this.$wait.end('loadUnassignedOrders')
    }
  }

  private async loadDrivers() {
    this.$wait.start('load.logistics.drivers')
    const response = await axios.get('/v3/logistics/drivers')
    this.drivers = response.data.data.map((user) => ({
      ...user,
      name: user.profile ? user.profile.fullName : `${user.firstname} ${user.lastname}`,
    }))

    this.$wait.end('load.logistics.drivers')
  }
}
