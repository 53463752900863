
















































import { Component, Prop, Vue } from 'vue-property-decorator'
import LoadingWrapper from '@/components/helpers/LoadingWrapper.vue'
import { TableHeader } from '@/types/Vuetify'
import moment from 'moment'

@Component({
  components: { LoadingWrapper },
})
export default class SelectedLogisticsOrders extends Vue {
  @Prop()
  readonly orders!: any[]

  @Prop()
  readonly route!: google.maps.DirectionsResult | null

  headers: TableHeader[] = [
    {
      text: '#',
      value: 'index',
      sortable: false,
    },
    {
      text: 'ETA',
      value: 'eta',
      sortable: false,
      // @ts-ignore
      align: 'start text-no-wrap',
    },
    {
      text: 'Order',
      value: 'id',
      sortable: false,
    },
    {
      text: 'Customer',
      value: 'deliveryName',
      sortable: false,
    },
    {
      text: 'Place',
      value: 'normalizedAddress.formattedAddress',
      sortable: false,
    },
    {
      text: 'Products ready for delivery',
      value: 'readyProductsCount',
      sortable: false,
    },
  ]

  selectedForUnassignment: any[] = []

  get tripTime() {
    if (this.route !== null) {
      return moment
        .duration(
          this.route.routes[0].legs.reduce((sum, leg) => sum + leg.duration.value + 300, -300),
          's',
        )
        .humanize()
    }
    return this.$t('Unknown')
  }

  get tripLength() {
    if (this.route !== null) {
      const distance = this.route.routes[0].legs.reduce((sum, leg) => sum + leg.distance.value, 0)
      const formatter = new Intl.NumberFormat('sv-SE', { maximumSignificantDigits: 2 })
      return formatter.format(distance / 1000) + ' km'
    }
    return this.$t('Unknown')
  }

  removeSelectedRoutes() {
    if (this.selectedForUnassignment.length === 0) return

    this.$emit(
      'remove',
      this.selectedForUnassignment.map((order) => order.id),
    )
    this.selectedForUnassignment = []
  }
}
