










































































import OrderModal from '@/components/logistics/OrderModal.vue'
import ScrollWrapper from '@/components/helpers/ScrollWrapper.vue'
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { TableHeader } from '@/types/Vuetify'

@Component({
  components: { OrderModal, ScrollWrapper },
})
export default class UnassignedLogisticsOrders extends Vue {
  @Prop()
  readonly customerNameFilter!: string

  @Prop()
  readonly supplierId!: number

  @Prop()
  readonly unselectedOrders!: any[]

  @Prop()
  readonly locations!: any[]

  @PropSync('locationId')
  syncLocationId!: number

  ordersHeaders: TableHeader[] = [
    {
      text: 'Order',
      value: 'id',
    },
    {
      text: 'Customer',
      value: 'deliveryName',
    },
    {
      text: 'Place',
      value: 'deliveryPlace',
    },
    {
      text: 'Products ready for delivery',
      value: 'readyProductsCount',
    },
  ]

  selectedForAssignment = []

  dialog = false
  displayedOrderId: number = null

  get displayedOrder() {
    return this.unselectedOrders.find(({ id }) => id === this.displayedOrderId) || null
  }

  displayOrder(orderId) {
    this.displayedOrderId = orderId
    this.dialog = true
  }

  submitSelectedRoutes() {
    this.$emit(
      'select',
      this.selectedForAssignment.map((order) => order.id),
    )
    this.selectedForAssignment = []
  }
}
