import Vue from 'vue'

export function WaitStart(loader: string): MethodDecorator {
  return (target, key, descriptor: TypedPropertyDescriptor<any>): PropertyDescriptor => {
    return {
      ...descriptor,
      get() {
        return function (...args) {
          // @ts-ignore
          const that = this as Vue
          that.$wait.start(loader)
          try {
            descriptor.get().bind(that)(...args)
          } catch (e) {
            throw e
          }
        }
      },
    }
  }
}
